// app/javascript/controllers/two_factor_auth_controller.js
import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["toggle", "status", "backupCodes", "otpField", "submitButton", "countdown", "lockTimer"];

  connect() {
    if (this.hasToggleTarget) {
    this.updateStatus(); // Initial update based on the current state
    this.toggleTarget.addEventListener("change", () => this.handleToggleChange());
  }
  if (this.hasCountdownTarget) {
    this.startCountdown(); // Start the countdown timer
  }
}

  updateStatus() {
    const status = this.toggleTarget.checked ? "Enabled" : "Disabled";
    if (this.statusTarget) {
      this.statusTarget.textContent = status;
    } else {
      console.error("Status target not found");
    }
  }

  handleToggleChange() {
    const url = `/user/${this.data.get("userId")}/update_two_factor_auth`;
    const data = {
      otp_required_for_login: this.toggleTarget.checked.toString() // Convert boolean to string for params
    };

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        console.log("Two-factor authentication status updated");
        this.updateStatus();
      } else {
        console.error("Error updating status:", data.errors);
      }
    })
    .catch(error => console.error("Error:", error));
  }

  copyToClipboard(event) {
    event.preventDefault(); // Prevent any default behavior like form submission or page navigation
  
    if (!this.hasBackupCodesTarget) {
      console.error("Backup codes target not found");
      return;
    }
  
    const backupCodes = this.backupCodesTarget.innerText;
    navigator.clipboard.writeText(backupCodes).then(() => {
      // Show a message indicating success
      const successMessage = document.createElement('div');
      successMessage.textContent = 'Backup codes copied!';
      successMessage.classList.add('text-green-600', 'mt-2');
      this.element.appendChild(successMessage);
  
      // Optionally, remove the message after a few seconds
      setTimeout(() => {
        successMessage.remove();
      }, 3000);
      
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
  startCountdown() {
    const countdownElement = this.countdownTarget
    let timeRemaining = parseInt(countdownElement.innerText)

    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        timeRemaining -= 1
        countdownElement.innerText = timeRemaining
      } else {
        // Re-enable the input and submit button when countdown is over
        clearInterval(interval)
        this.otpFieldTarget.disabled = false
        this.submitButtonTarget.disabled = false
      }
    }, 1000) // Update every second
  }

}
